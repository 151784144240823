import React, { Component } from "react";
import { Link } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import { formatDate } from "../../utils/dateUtils";
import { privateApiCall } from "../../api/privateApi";
import {
  FiMessageCircle,
  FiXCircle,
  FiCheckCircle,
  FiEye,
} from "react-icons/fi";
import ImageModal from "../../images/imageModal";
import { FaImage } from "react-icons/fa";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Tooltip } from "react-tooltip";
import {
  APPROVE,
  BUY_PRODUCT,
  DASHBOARD,
  FEED,
  REJECT,
  VIEW_DET,
  VIEW_IMG,
} from "../../constants/url";
import {
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
  ALERT_ICON_ERR,
  ALERT_ICON_SUCC,
  CONFIRM_TITLE,
  NO_DATA_FOUND,
  NO_EXP,
  CONFIRM_TXT_ACC_ALL,
  CONFIRM_TXT_REJ_ALL,
  CONFIRM__ACC_ALL_YES_BUTTON,
  DELETE_YES_BUTTON,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ERR_POSTING,
  REJECTED,
  SUCC_ALERT_TITLE,
  SUCC_APPROVE_TEXT,
  SUCC_APPROVE_TITLE,
  SUCC_FEEDBACK,
  SUCC_REJECTPRODUCT_TITLE,
  SUCC_REJECTPRODUCT_TXT,
  SUCC_REJECT_ALL_PRODUCTS_TITLE,
  WARNING,
} from "../../constants/alertMessage";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Grid,
  Dialog,
  TextField,
} from "@mui/material";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();
class appList extends Component {
  constructor() {
    const localRole = JSON.parse(localStorage.getItem("role"));
    super();
    this.state = {
      data: "",
      replay: "",
      selectedRows: [],
      open: false,
      images: "",
      open1: false,
      open2: false,
      feedId: "",
      customer: "",
      mobile_no: "",
      title: "",
      description: "",
      qty: "",
      unit: "",
      endDate: "",
      startDate: "",
      permissions:
        localRole && localRole.permissions ? localRole.permissions : "All",
    };
    this.acceptAll = this.acceptAll.bind(this);
    this.rejectAll = this.rejectAll.bind(this);
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }
  componentDidMount() {
    this.fetchPendingListData();
  }

  addSerialNumbers(data) {
    return data.map((item, index) => ({
      ...item,
      sno: index + 1,
    }));
  }

  fetchPendingListData() {
    const requestUrl = `${BUY_PRODUCT}/buy_pending_list/`;
    privateApiCall(requestUrl, "GET")
      .then(
        (res) => {
          const data = res.data.data;
          if (data.length === 0) {
            Swal.fire({
              title:`${NO_DATA_FOUND}`,
              icon: "info",
              text: `${NO_EXP}`,
              allowOutsideClick: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }

         else
         {
          for (let i = 0; i < data.length; i++) {
            data[i].sno = i + 1;
          }

          this.setState({ data });
         }
        },
        (err) => {
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${err}`,
            icon: `${ALERT_ICON_ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      )
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }

  handleSearch(e) {
    const searchValue = e.target.value;
    const requestUrl = `${BUY_PRODUCT}/search_buy_pending_list?query=${searchValue}&`;

    privateApiCall(requestUrl, "GET")
      .then((response) => {
        if (response.data && response.data.data) {
          const dataWithSerialNumbers = this.addSerialNumbers(
            response.data.data
          );
          this.setState({ data: dataWithSerialNumbers });
        } else {
          this.setState({ data: [] });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }

  handleCheckBox(e, row) {
    const { selectedRows } = this.state;
    const selectedIndex = selectedRows.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    this.setState({ selectedRows: newSelected });
  }

  handleClose = () => {
    this.setState({ open: false });
  };
  handleClose1 = () => {
    this.setState({ open1: false });
  };
  handleClose2 = () => {
    this.setState({ open2: false });
  };

  handleReplay = (event) => {
    event.preventDefault();

    const requestUrl = `${BUY_PRODUCT}/replayToBProduct/?&_id=${this.state.feedId}&replay=${this.state.replay}`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        if (res.data.status) {
          const requestUrl = `${BUY_PRODUCT}/buy_pending_list/`;
          privateApiCall(requestUrl, "GET")
            .then(
              (res) => {
                const k = 1;

                for (let i = 0; i < res.data.data.length; i++) {
                  res.data.data[i].sno = k + i;
                }

                this.setState({
                  open2: false,
                  replay: "",
                  data: res.data.data,
                });

                Swal.fire({
                  title: `${SUCC_ALERT_TITLE}`,
                  text: `${SUCC_FEEDBACK}`,
                  icon: `${ALERT_ICON_SUCC}`,
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonText: `${ALERT_BUTTON_OK}`,
                });
              },
              (err) => {
                Swal.fire({
                  title: `${ERR_ALERT_TITLE}`,
                  icon: `${ALERT_ICON_ERR}`,
                  text: `${ERR_POSTING}`,
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonText: `${ALERT_BUTTON_OK}`,
                });
              }
            )
            .catch((err) => {
              Swal.fire({
                text: `${ERR_POSTING}`,
                title: `${ERR_ALERT_TITLE}`,
                icon: `${ALERT_ICON_ERR}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            });
        } else {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_POSTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };

  handleChange(event) {
    this.setState({
      replay: event.target.value,
    }); // setValues({ ...values, error: false, [name]: e.target.value });
  }
  edit(cell, row, extraData, index) {
    const handleImageClick = () => {
      if (row.image1) {
        this.setState({ images: [row.image1], open: true });
      }
    };
    const isOnlyImage1Present =
      row.image1 !== undefined && row.image1 !== null && row.image1 !== "";

    const handleS = (e) => {
      // Perform the state updates only if confirmed
      this.setState({ feedId: row.id });
      this.setState({ open2: true });
    };
    // const handleOnChange = (e) => {
    //   e.preventDefault();
    //   Swal.fire({
    //     title: `${APPROVE} ${row.title}`,
    //     text: "",
    //     icon: `${ALERT_ICON_SUCC}`,
    //     allowOutsideClick: false,
    //     confirmButtonText: `${ALERT_BUTTON_YES}`,
    //     showCancelButton: true,
    //   })
    //     .then((result) => {
    //       if (result.isConfirmed) {
    //         const requestUrl = `${BUY_PRODUCT}/approveBuyProduct/${row.id}/`;
    //         privateApiCall(requestUrl, "GET")
    //           .then((res) => {
    //             console.log(res,"response after assign")
    //             if (res.status === 200) {
    //               let updatedSelections = [...this.state.data];  

            
    //         updatedSelections.splice(index, 1);


            
    //         for (let i = 0; i < updatedSelections.length; i++) {
    //           updatedSelections[i].sno = i + 1;  
    //         }

            
    //         this.setState({
    //           data: updatedSelections,
    //         });

    //               // Success Swal
    //               Swal.fire({
    //                 title: `${SUCC_APPROVE_TITLE}`,
    //                 text: `${SUCC_APPROVE_TEXT}`,
    //                 icon: `${ALERT_ICON_SUCC}`,
    //                 timer: 1100,
    //                 showConfirmButton: false,
    //               });
    //             }
    //           })
    //           .catch((err) => {
    //             Swal.fire({
    //               title: `${ERR_ALERT_TITLE}`,
    //               icon: `${ALERT_ICON_ERR}`,
    //               text: `${ERR_POSTING}`,
    //               allowOutsideClick: false,
    //               showCancelButton: false,
    //               confirmButtonText: `${ALERT_BUTTON_OK}`,
    //             });
    //           });
    //       }
    //     })
    //     .catch(() => {
    //       Swal.fire({
    //         title: `${ERR_ALERT_TITLE}`,
    //         icon: `${ALERT_ICON_ERR}`,
    //         text: `${ERR_POSTING}`,
    //         allowOutsideClick: false,
    //         showCancelButton: false,
    //         confirmButtonText: `${ALERT_BUTTON_OK}`,
    //       });
    //     });
    // };


    const handleOnChange = (e) => {
      e.preventDefault();
      Swal.fire({
        title: `${APPROVE} ${row.title}`,
        text: "",
        icon: `${ALERT_ICON_SUCC}`,
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const requestUrl = `${BUY_PRODUCT}/approveBuyProduct/${row.id}/`;
          privateApiCall(requestUrl, "GET")
            .then((res) => {
              if (res.status === 200 && res.data.status) {
                let updatedSelections = [...this.state.data];
                updatedSelections.splice(index, 1);
                for (let i = 0; i < updatedSelections.length; i++) {
                  updatedSelections[i].sno = i + 1;
                }
                this.setState({ data: updatedSelections });
                Swal.fire({
                  title: `${SUCC_APPROVE_TITLE}`,
                  text: `${SUCC_APPROVE_TEXT}`,
                  icon: `${ALERT_ICON_SUCC}`,
                  timer: 1100,
                  showConfirmButton: false,
                });
              } else {
                Swal.fire({
                  title: "Approval Alert",
                  text: res.data.message || "An error occurred during approval.",
                  icon: "warning",
                  allowOutsideClick: false,
                  showConfirmButton: true,
                });
              }
            })
            .catch((err) => {
              Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                icon: `${ALERT_ICON_ERR}`,
                text: `${ERR_POSTING}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            });
        }
      });
    };
    
    const handleOnReject = (event) => {
      event.preventDefault();

      Swal.fire({
        title: `${REJECT} ${row.title}`,
        text: "",
        icon: `${ALERT_ICON_ERR}`,
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      })
        .then((result) => {
          if (result.isConfirmed) {
            const requestUrl = `${BUY_PRODUCT}/rejectBuyProduct/${row.id}/`;
            privateApiCall(requestUrl, "GET")
              .then((res) => {
                if (res.status === 200) {
                  let updatedSelections = [...this.state.data];  

            
            updatedSelections.splice(index, 1);

            
            for (let i = 0; i < updatedSelections.length; i++) {
              updatedSelections[i].sno = i + 1;  
            }

            
            this.setState({
              data: updatedSelections,
            });

                  // Success Swal
                  Swal.fire({
                    title: `${SUCC_REJECTPRODUCT_TITLE}`,
                    text: `${SUCC_REJECTPRODUCT_TXT}`,
                    icon: `${ALERT_ICON_SUCC}`,
                    timer: 1100,
                    showConfirmButton: false,
                  });
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: `${ERR_ALERT_TITLE}`,
                  icon: `${ALERT_ICON_ERR}`,
                  text: `${ERR_POSTING}`,
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonText: `${ALERT_BUTTON_OK}`,
                });
              });
          }
        })
        .catch(() => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_POSTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        });
    };

    const handlePOP = (e) => {
      const {
        customer,
        mobile_no,
        name,
        expDate,
        description,
        title,
        qty,
        unit,
        location,
        startDate,
        endDate,
        safe_status,
        organic_status,
        transpot_status,
      } = row;
      this.setState({
        customer: customer,
        mobile_no: mobile_no,
        name: name,
        expDate: expDate,
        description: description,
        title: title,
        qty: qty,
        unit: unit,
        location: location,
        startDate: startDate,
        endDate: endDate,
        safe_status,
        organic_status,
        transpot_status,
      });
      this.setState({ open1: true });
    };
    return (
      <span>
        {this.state.permissions.some(
          (item) =>
            item.keyword === "buyProductPendingList" && item.isApprove === true
        ) && (
          <>
            {" "}
            <i data-tooltip-id="1" data-tooltip-content={`${APPROVE}`}>
              <FiCheckCircle
                onClick={handleOnChange}
                color="#4fbde9"
                size="20"
              />
            </i>
            <Tooltip id="1" />
          </>
        )}
        {this.state.permissions.some(
          (item) =>
            item.keyword === "buyProductPendingList" && item.isReject === true
        ) && (
          <>
            {" "}
            <i
              className="ml-1"
              data-tooltip-id="2"
              data-tooltip-content={`${REJECT}`}
            >
              {" "}
              <FiXCircle onClick={handleOnReject} color="#f36270" size="20" />
            </i>
            <Tooltip id="2" />
          </>
        )}
        {this.state.permissions.some(
          (item) =>
            item.keyword === "buyProductPendingList" && item.isFeedback === true
        ) && (
          <>
            {row.replay !== "" ? (
              <i
                className="ml-1"
                data-tooltip-id="3"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle onClick={handleS} color="#769A0F" size="20" />
              </i>
            ) : (
              <i
                className="ml-1"
                data-tooltip-id="3"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle onClick={handleS} color="#f9bc0b" size="20" />
              </i>
            )}
            <Tooltip id="3" />
          </>
        )}
        {this.state.permissions.some(
          (item) =>
            item.keyword === "buyProductPendingList" &&
            item.isDetailedInfo === true
        ) && (
          <>
            <i
              className="ml-1"
              data-tooltip-id="4"
              data-tooltip-content={`${VIEW_DET}`}
            >
              {" "}
              <FiEye onClick={handlePOP} color="#9368f3" size="20" />
            </i>
            <Tooltip id="4" />
          </>
        )}
  {this.state.permissions.some(
          (item) =>
            item.keyword === "buyProductPendingList" &&
            item.isShowImage === true
        ) && (
          <>
             {isOnlyImage1Present && (
          <i
            className="ml-1"
            data-tooltip-id="4"
            data-tooltip-content={`${VIEW_IMG}`}
          >
            {" "}
            <FaImage onClick={handleImageClick} color="green" size="17" />
          </i>
        )}
        <Tooltip id="5" />
          </>
        )}
       
      </span>
    );
  }

  delete(cell, row) {
    return (
      <span>
        <button
          type="submit"
          className="btn btn-icon waves-effect waves-light btn-danger"
          data-toggle="modal"
          data-target={"#myModal" + row.id}
        >
          {" "}
          <i className="fa fa-remove"></i>{" "}
        </button>

        <div
          id={"myModal" + row._id}
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <form
              method="post"
              action={
                "/delete_category/" +
                row.id +
                "/?token=" +
                cookies.get("asia_page_token")
              }
            >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h5 className="modal-title" id="myModalLabel">
                    {STRING_CONSTANTS.WARNING}
                  </h5>
                </div>
                <div className="modal-body">Are you sure?</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    {STRING_CONSTANTS.CLOSE}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    {STRING_CONSTANTS.DELETE}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </span>
    );
  }

  acceptAll = (event) => {
    event.preventDefault();

    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: `${CONFIRM_TXT_ACC_ALL}`,
      icon: `${WARNING}`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${CONFIRM__ACC_ALL_YES_BUTTON}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestUrl = `${BUY_PRODUCT}/approveBuyProductAll/`;
        privateApiCall(requestUrl, "POST", {
          selectedRows: this.state.selectedRows,
        })
          .then((res) => {
            if (res.status === 200) {
              this.fetchPendingListData();

              this.setState({
                selectedRows: [],
              });

              // Show success message
              Swal.fire({
                title: `${SUCC_APPROVE_TITLE}`,
                text: `${SUCC_APPROVE_TEXT}`,
                icon: `${ALERT_ICON_SUCC}`,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            }
          })
          .catch((err) => {
            // Show error message
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              text: `${ERR_POSTING}`,
              icon: `${ALERT_ICON_ERR}`,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          });
      }
    });
  };

  rejectAll = (event) => {
    event.preventDefault();

    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: `${CONFIRM_TXT_REJ_ALL}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${DELETE_YES_BUTTON}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestUrl = `${BUY_PRODUCT}/rejectBuyProductAll/`;
        privateApiCall(requestUrl, "POST", {
          selectedRows: this.state.selectedRows,
        })
          .then((res) => {
            if (res.status === 200) {
              this.fetchPendingListData();

              this.setState({
                selectedRows: [],
              });

              // Show success message
              Swal.fire({
                title: `${REJECTED}`,
                text: `${SUCC_REJECT_ALL_PRODUCTS_TITLE}`,
                icon: `${ALERT_ICON_SUCC}`,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            }
          })
          .catch((err) => {
            // Show error message
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              text: `${ERR_POSTING}`,
              icon: `${ALERT_ICON_ERR}`,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          });
      }
    });
  };

  render() {
    const { data, selectedRows } = this.state;

    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" />
        <Sidebar type="buyProducts" />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title float-left">
                      {STRING_CONSTANTS.PENDING_LIST}
                    </h4>

                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.BUY_PRODUCTS}
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.PENDING_LIST}
                      </li>
                    </ol>

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-box">
                    <div
                      style={{
                        width: "98%",
                      }}
                    >
                      <div className="row">
                        {this.state.permissions.some(
                          (item) =>
                            item.keyword === "buyProductPendingList" &&
                            item.isApprove === true
                        ) && (
                          <div className="box1 ml-2">
                            <div id="toolbar">
                              <form onSubmit={this.acceptAll}>
                                {this.state.selectedRows.length === 0 ? (
                                  <button
                                    type="submit"
                                    id="button"
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                    className="btn btn-icon waves-effect pb-1 waves-light btn-info"
                                    disabled
                                  >
                                    {" "}
                                    <i className="fa fa-check"></i>
                                    {STRING_CONSTANTS.APPROVE}
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    id="button"
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                    className="btn btn-icon  waves-effect waves-light btn-info"
                                  >
                                    {" "}
                                    <i className="fa fa-check"></i>
                                    {STRING_CONSTANTS.APPROVE}
                                  </button>
                                )}
                              </form>
                            </div>
                          </div>
                        )}

                        {this.state.permissions.some(
                          (item) =>
                            item.keyword === "buyProductPendingList" &&
                            item.isReject === true
                        ) && (
                          <div className="box1 ml-2">
                            <div id="toolbar">
                              <form onSubmit={this.rejectAll}>
                                {this.state.selectedRows.length === 0 ? (
                                  <button
                                    id="remove"
                                    type="submit"
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                    className="btn btn-icon waves-effect waves-light pb-1 btn-danger"
                                    disabled
                                  >
                                    {" "}
                                    <i className="fa fa-times "></i>{" "}
                                    {STRING_CONSTANTS.REJECT}
                                  </button>
                                ) : (
                                  <button
                                    id="remove"
                                    type="submit"
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                    className="btn btn-icon waves-effect  waves-light btn-danger"
                                  >
                                    {" "}
                                    <i className="fa fa-times"></i>{" "}
                                    {STRING_CONSTANTS.REJECT}
                                  </button>
                                )}
                              </form>
                            </div>
                          </div>
                        )}
                        <div
                          style={{
                            marginLeft: "auto",
                            width: "200px",
                            marginRight: "40px",
                            position: "relative",
                          }}
                        >
               
               {this.state.permissions.some(
          (item) =>
            item.keyword === "buyProductPendingList" &&
            item.isSearch === true
        ) && (
          <>
             <input
                            type="text"
                            placeholder="Search..."
                            className="form-control"
                            style={{
                              width: "100%",
                              paddingLeft: "30px",
                              boxSizing: "border-box",
                            }}
                            onChange={(e) => {
                              this.handleSearch(e);
                              if (e.target.value) {
                                e.target.nextSibling.style.display = "none";
                              } else {
                                e.target.nextSibling.style.display = "block";
                              }
                            }}
                          />
                          <i
                            className="fas fa-search"
                            style={{
                              position: "absolute",
                              left: "10px",
                              top: "40%",
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                              color: "#aaa",
                            }}
                          ></i>
          </>
        )}

                        

                        </div>
                      </div>
                      <div style={{ width: "98%" }}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  <Checkbox
                                    style={{ transform: "scale(0.7)" }}
                                    align="left"
                                    checked={
                                      data.length > 0 &&
                                      selectedRows.length === data.length
                                    }
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      const newSelected = checked ? data : [];
                                      this.setState({
                                        selectedRows: newSelected,
                                      });
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.SN_NO}
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.CUSTOMER}
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.MOBILE}
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.TITLE}
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.ACTIONS}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Array.isArray(this.state.data) &&
                                this.state.data.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="left">
                                      <Checkbox
                                        style={{ transform: "scale(0.7)" }}
                                        align="left"
                                        checked={
                                          selectedRows.indexOf(row) !== -1
                                        }
                                        onChange={(e) => {
                                          this.handleCheckBox(e, row);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.sno}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.customer}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.mobile_no}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.title}
                                    </TableCell>
                                    <TableCell align="left">
                                      {this.edit(null, row, null, index)}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              <ImageModal
                                handleClose={this.handleClose}
                                open={this.state.open}
                                imageSrc={this.state.images}
                              />

                              <Dialog
                                open={this.state.open1}
                                onClose={this.handleClose1}
                                aria-labelledby="details-modal-title"
                                aria-describedby="details-modal-description"
                                style={{
                                  maxHeight: "80vh",
                                  overflowY: "auto",
                                  marginTop: "100px",
                                }}
                                fullWidth
                                disableEnforceFocus
                                maxWidth="lg"
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  className="modal-content"
                                >
                                  <Grid item xs={12} className="modal-header">
                                    <h5 id="details-modal-title">
                                      {" "}
                                      {STRING_CONSTANTS.PRODUCT_DETAILS}
                                    </h5>
                                    <Button
                                      onClick={this.handleClose1}
                                      color="inherit"
                                    >
                                      {" "}
                                      <span
                                        style={{ fontSize: "20px" }}
                                        aria-hidden="true"
                                      >
                                        &#128473;
                                      </span>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} className="modal-body">
                                    <Table className="col-12">
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.CUSTOMER_NAME}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.customer}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.CUSTOMER_MOBILE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.mobile_no}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.PRODUCT_TITLE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.title}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.DESCRIPTION}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.description}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.QUANTITY}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.qty}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.UNIT}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.unit}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.LOCATION}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.location}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.START_DATE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {formatDate(this.state.startDate)}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.END_DATE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {formatDate(this.state.endDate)}
                                        </TableCell>
                                      </TableRow>
                                    </Table>
                                  </Grid>
                                  <Grid item xs={12} className="modal-footer">
                                    <Button
                                      onClick={this.handleClose1}
                                      variant="outlined"
                                      color="error"
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Dialog>
                              <Dialog
                                open={this.state.open2}
                                onClose={this.handleClose2}
                                maxWidth="lg"
                                fullWidth
                                centered
                                disableEnforceFocus
                              >
                                <div
                                  style={{
                                    backgroundColor: "#fff",
                                    padding: "20px",
                                    // minWidth: '500px',
                                    borderRadius: "8px",
                                  }}
                                >
                                  <h3>{STRING_CONSTANTS.FEEDBACK_LABEL}</h3>
                                  <form onSubmit={this.handleReplay}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <div className="text-left text-dark mb-1">
                                          {STRING_CONSTANTS.MESSAGE}
                                        </div>
                                        <TextField
                                          type="text"
                                          fullWidth
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          value={this.state.replay}
                                          placeholder={STRING_CONSTANTS.MESSAGE}
                                          onChange={this.handleChange}
                                          required
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      justifyContent="flex-end"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={this.handleClose2}
                                        style={{ marginRight: "8px" }}
                                      >
                                        {STRING_CONSTANTS.CLOSE}
                                      </Button>
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        style={{
                                          backgroundColor: "#769A0F",
                                          color: "#fff",
                                        }}
                                      >
                                        {STRING_CONSTANTS.SEND}
                                      </Button>
                                    </Grid>
                                  </form>
                                </div>
                              </Dialog>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default appList;
